import styled from "styled-components";
import { fonts } from "../styles";

export const Hero = styled.header`
  min-height: 47rem;
  padding: 8rem 80px 8rem 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px 8rem 16px;
  }

  .main-landing-container-row {
    display: flex;
    justify-content: space-between;
    grid-gap: 4rem;

    @media (max-width: 920px) {
      flex-direction: column;
    }
  }

  article {
    position: relative;
    z-index: 1;
      max-width: 566px;
  }

  figure {
    align-self: flex-end;

    img {
      max-width: 33rem;
      object-fit: scale-down;
      margin-right:10px;
    }
  }
`;

export const InfoSection = styled.section`
  padding: 12rem 80px 8rem 80px;

  @media (max-width: 600px) {
    padding: 12rem 32px 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 12rem 16px 8rem 16px;
  }

  .main-landing-container-column {
    align-items: unset;
  }
    
  .info-section-list {
    display: flex;
    flex-direction: column;
    grid-gap: 4rem;
      
  }
`;

export const ContactSection = styled.section`
  padding: 0 80px 0 80px;
    
  @media (max-width: 600px) {
    padding: 10rem 32px 8.5rem 32px;
  }

  @media (max-width: 425px) {
    padding: 10rem 16px 8.5rem 16px;
  }

  .contact-section {
    justify-content: space-between;
    grid-gap: 6rem;
    width: 100%;
    position: relative;
    @media (max-width: 920px) {
      flex-direction: column;
      align-items: center;
    }
  }

  figure {
    max-width: 205px;
    width: 100%;
      position: absolute; 
      bottom: 0; 
      right: 100px; 
      @media (max-width: 920px) {
          margin: auto; 
          position: relative; 
          bottom: auto;
          right: auto;
      }

      img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  article {
    max-width: 51.1rem;
    width: 100%;

    .contact-button {
      border: none;
      outline: none;
      border-radius: 21px;
      padding: 0 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
  }
`;
